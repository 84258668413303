import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { base, container, icon, linked } from './IconBlock.styles'

function IconBlock({ iconObject, text, link }) {
  const image = getImage(iconObject.gatsbyImage)
  return (
    <div tw="items-start mt-0">
      {link ? (
        <Link to={link} css={linked}>
          <div css={[container.base, container.size, container.link]}>
            {image && <GatsbyImage image={image} alt={iconObject.altText} css={icon} imgStyle={{ objectFit: `contain` }} />}
          </div>
          <p tw="text-sm">{text}</p>
        </Link>
      ) : (
        <div css={base}>
          <div css={[container.base, container.size]}>
            {image && <GatsbyImage image={image} alt={iconObject.altText} css={icon} imgStyle={{ objectFit: `contain` }} />}
          </div>
          <p tw="text-sm">{text}</p>
        </div>
      )}
    </div>
  )
}

IconBlock.propTypes = {
  iconObject: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
}

export default IconBlock
