import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { textCol, container, iconGrid } from './FeatureGrid.styles'
import Container from '../../components/Container/Container'
import IconBlock from '../../components/IconBlock/IconBlock'

const FeatureGrid = ({ iconBlocks, description }) => (
  <Container variant="wide" css={container}>
    <div css={textCol}>
      <h3 tw="text-[24px] leading-8">{description}</h3>
    </div>
    {iconBlocks?.length ? (
      <div css={iconGrid}>
        {iconBlocks.map(block => {
          if (block.link) {
            const parsedLink = new URL(block.link.url)
            return (
              <IconBlock
                link={block.link.url.startsWith('https://marketing.umbrellafamily') ? parsedLink.pathname : block.link.url}
                key={block.text}
                iconObject={block.icon}
                text={block.text}
              />
            )
          }
          return <IconBlock key={block.text} iconObject={block.icon} text={block.text} />
        })}
      </div>
    ) : null}
  </Container>
)

FeatureGrid.propTypes = {
  iconBlocks: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.node]),
  description: PropTypes.string.isRequired,
}

export default FeatureGrid
