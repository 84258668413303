import tw, { css } from 'twin.macro'

export const dropdown = {
  wrapper: tw`flex pb-7 tablet:max-w-full`,
  textContainer: tw`justify-center items-center m-auto text-purple w-full`,
  collapsed: tw`hidden`,
  expanded: tw`text-black text-base mt-3`,
  button: tw`w-11 h-10 ml-5 bg-purple`,
  icon: tw`h-6 w-6 text-white m-auto justify-center items-center`,
}

export const faq = {
  wrapper: tw`flex space-x-10 py-10 justify-center desklet:flex-col phablet:px-0 tablet:py-5`,
  leftCol: {
    wrapper: tw`flex flex-col w-2/5 mt-0 desklet:flex-row desklet:items-center desklet:w-full desklet:px-8 desklet:space-x-10 tablet:flex-col tablet:px-0`,
    text: tw`flex flex-col desklet:w-1/2 desklet:justify-center tablet:w-full tablet:justify-start`,
    image: tw`rounded-3xl max-w-[400px] desklet:w-1/2 tablet:w-full tablet:max-w-full tablet:!ml-0`,
  },
  rightCol: {
    wrapper: tw`flex flex-col space-y-10 m-auto w-3/5 desklet:mt-8 desklet:w-full desklet:!ml-0 desklet:px-8 tablet:px-0 `,
    dropdown: tw`border-b-2 border-purple`,
  },
}
