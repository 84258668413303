import React from 'react'
import PropTypes from 'prop-types'
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { dropdown, faq } from './Faq.styles'
import Wysiwyg from '../../components/Wysiwyg'
import Container from '../../components/Container'

const FaqDropdown = ({ question, answer }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true)
  return (
    <div css={dropdown.wrapper}>
      <div css={dropdown.textContainer}>
        <div tw="text-lg font-bold">{question}</div>
        <Wysiwyg css={isCollapsed ? dropdown.collapsed : dropdown.expanded} aria-expanded={isCollapsed}>
          {answer}
        </Wysiwyg>
      </div>
      <button onClick={() => setIsCollapsed(!isCollapsed)} css={dropdown.button} type="button" aria-label="View More">
        {isCollapsed ? <PlusIcon css={dropdown.icon} /> : <MinusIcon css={dropdown.icon} />}
      </button>
    </div>
  )
}

FaqDropdown.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
}

const Faq = ({ title, text, questions, image }) => {
  const imageObject = getImage(image?.gatsbyImage)

  return (
    <Container variant="wide" css={faq.wrapper}>
      <div css={faq.leftCol.wrapper}>
        <div css={faq.leftCol.text}>
          <h2 tw="text-left">{title}</h2>
          <Wysiwyg tw="text-left mb-5">{text}</Wysiwyg>
        </div>
        {imageObject && (
          <GatsbyImage image={imageObject} alt={image?.altText} css={faq.leftCol.image} imgStyle={{ borderRadius: `1.5rem` }} />
        )}
      </div>
      {questions?.length ? (
        <div css={faq.rightCol.wrapper}>
          {questions.map((q, index) => (
            <div key={q.id} style={index !== questions.length - 1 ? { borderBottom: '1px solid purple' } : { border: 'none' }}>
              <FaqDropdown collapsed question={q.faq.question} answer={q.faq.answer} />
            </div>
          ))}
        </div>
      ) : null}
    </Container>
  )
}

Faq.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  questions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.node]),
  image: PropTypes.object,
}

export default Faq
