import tw, { css } from 'twin.macro'

export const base = tw`text-center text-black`
export const linked = tw`text-center text-black hover:text-purple`

export const container = {
  base: tw`mb-2 mx-auto bg-yellow-100 p-3 rounded-full`,
  size: css`
    width: fit-content;
  `,
}

export const icon = tw`w-[40px] h-[40px]`
