import tw, { css } from 'twin.macro'

export const container = [tw`flex py-10 justify-between tablet:flex-col phablet:px-0 tablet:py-5`]

export const textCol = tw`w-1/3 my-auto pr-10 tablet:pr-0 tablet:w-full tablet:text-center tablet:mb-8`

export const iconGrid = [
  tw`w-2/3 items-start tablet:w-full`,
  css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  `,
]
